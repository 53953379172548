<template>
  <div class="row">
    <div class="col-12">
      <p class="font-21">
        Report dates
      </p>
      <p class="font-15">
        We will try to complete the report as soon as possible.
        We are usually able to complete the report within 10 days.
      </p>
      <ElDivider class="mt-4 mt-md-4" />
      <div class="font-21 pb-1">
        Tenant coordination
      </div>
      <p class="font-15">
        We will make sure to coordinate the report time with the tenant.
        We will cc you on the initial email message the tenant receives regarding the report.
      </p>
      <TenantsForm
        :lead="payload.lead"
        :tenants="payload.tenants"
        prop-path="tenants"
        @updated="updateTenants"
      />
      <ElDivider class="mt-4" />
      <p class="font-21">
        Special instructions
      </p>
      <div class="row">
        <GeneralNotes
          class="col-12"
          :general-notes.sync="payloadRef.instructions"
          tooltip-text="Any additional instructions to our agent or emphasis on items to review during the report"
          placeholder-text="Notes or any specific things the agent should check"
          field-name="instructions"
          @change="emitPayloadChanged"
        />
      </div>
      <ElAlert
        type="info"
        show-icon
        :closable="false"
        class="mt-4"
      >
        <div>
          <div class="p-2">
            <p class="font-weight-strong">
              Please note:
            </p>
            <span class="font-15">
              We will coordinate with the tenant who is expected to provide access to
              the property. We will not be able to conduct the report if we will not be
              able to coordinate with the tenant within 10 days.
            </span>
          </div>
        </div>
      </ElAlert>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import GeneralNotes from '@/components/common/GeneralNotes';
import TenantsForm, { defaultNewTenant } from '@/components/tenant/TenantsForm';

export default {
  name: 'ConditionReportPeriodicGeneralInfo',
  components: { TenantsForm, GeneralNotes },
  props: {
    payload: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    const payloadRef = ref(defaultPayload);

    emitPayloadChanged();
    return {
      emitPayloadChanged,
      updateTenants,
      payloadRef,
    };

    async function submit() {
      await context.refs.selectPropertyForm.validate();
      emitPayloadChanged();
    }

    function emitPayloadChanged() {
      context.emit('update-payload', {
        tenants: props.payload.tenants?.length ? props.payload.tenants : payloadRef.value.tenants,
        instructions: payloadRef.value.instructions,
      });
    }
    function updateTenants(tenants) {
      payloadRef.value.tenants = tenants;
      emitPayloadChanged();
    }
  },
};

const defaultPayload = {
  tenants: [{ ...defaultNewTenant }],
  instructions: null,
};
</script>
