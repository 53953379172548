<template>
  <div id="condition-report-periodic">
    <div
      v-show="stepsState.active <= stepsState.submit && stepsState.active > 0"
      class="steps-wrapper-transition"
      :style="transition.styles"
    />
    <div
      v-show="stepsState.active <= stepsState.submit && stepsState.active > 0"
      class="steps-wrapper"
    >
      <div
        class="step-wrapper"
        :class="getStepClass(1)"
        @click="selectStep(1)"
      >
        <span class="step-icon">1</span>
        <span class="step-title d-none d-md-block">General</span>
      </div>
      <div
        class="step-wrapper"
        :class="getStepClass(2)"
        @click="selectStep(2)"
      >
        <span class="step-icon">2</span>
        <span class="step-title d-none d-md-block">Order summary</span>
      </div>
    </div>
    <SdPage>
      <SdLoadingLayer :init="init">
        <template v-slot:loaded>
          <ElForm
            ref="conditionReportForm"
            :model="payload"
            :validate-on-rule-change="false"
            class="row align-items-center justify-content-center"
          >
            <div class="col-12 col-md-6">
              <ConditionReportGeneralHeader
                :property="propertyRef"
                :report-type="payload.type"
              />
              <div v-show="stepsState.active === 1">
                <ConditionReportPeriodicGeneralInfo
                  :payload="payload"
                  @update-payload="updatePayload"
                />
              </div>
              <div v-show="stepsState.active === 2">
                <div class="font-21">
                  Order Summary
                </div>
                <ConditionReportOrderSummary
                  :payload="payload"
                  :rules="payload.currentRules"
                  @update-payload="updatePayload"
                />
              </div>
              <div class="col-12 py-3" />
              <div class="text-right">
                <ElButton
                  :class="{ 'full-width-button': stepsState.active === stepsState.submit }"
                  :disabled="!(stepsState.active < stepsState.submit) && !payload.finalConfirmChecked"
                  :loading="submittingData"
                  type="primary"
                  size="medium"
                  @click="nextStep"
                >
                  {{ stepsState.active < stepsState.submit ? 'Next' : 'Confirm order' }}
                </ElButton>
              </div>
              <ConditionReportOrderSuccessDialog
                ref="conditionReportOrderSuccessDialog"
                :payload="payload"
              />
            </div>
          </ElForm>
        </template>
      </SdLoadingLayer>
    </SdPage>
  </div>
</template>

<script>
import get from 'lodash.get';
import { showErrorNotify } from '@/utils/NotifyUtil';
import {
  reactive, ref, watch, onMounted,
} from '@vue/composition-api';
import ConditionReportGeneralHeader from '@/components/condition-report/ConditionReportGeneralHeader';
import ConditionReportPeriodicGeneralInfo from '@/components/condition-report/ConditionReportPeriodicGeneralInfo';
import ConditionReportOrderSummary from '@/components/condition-report/ConditionReportOrderSummary';
import ConditionReportOrderSuccessDialog from '@/components/condition-report/ConditionReportOrderSuccessDialog';
import Price from '@/constants/Price';
import { getPricesValueByKey } from '@/utils/PriceUtil';

export default {
  name: 'ConditionReportPeriodic',
  components: {
    ConditionReportOrderSummary,
    ConditionReportPeriodicGeneralInfo,
    ConditionReportGeneralHeader,
    ConditionReportOrderSuccessDialog,
  },
  setup(props, context) {
    const store = context.root.$store;
    const propertyRef = ref({});
    const submittingData = ref(false);
    const transition = reactive({ styles: 'width:50%;border-radius: 0 50px 50px 0;' });
    const stepsState = reactive(defaultStepsState);
    const payload = reactive(defaultPayload);
    let nextPressed = false;

    watch(() => stepsState.active, changeTransition);
    onMounted(() => {
      payload.type = context.root.$route.meta.reportType;
    });
    return {
      init,
      stepsState,
      nextStep,
      payload,
      propertyRef,
      updatePayload,
      selectStep,
      getStepClass,
      transition,
      submittingData,
    };

    function openSuccessDialog() {
      context.refs.conditionReportOrderSuccessDialog.open();
    }

    function getStepClass(stepNumber) {
      let classes = '';
      if (stepNumber === stepsState.active) {
        classes += ' is-active';
      }
      if (stepNumber < stepsState.active) {
        classes += ' is-processed';
      }
      return classes;
    }

    function changeTransition() {
      const percent = stepsState.active === stepsState.submit ? 85 : 35;
      transition.styles = `width:${percent}%;border-radius: 0 50px 50px 0;`;
    }

    async function init() {
      if (context.root.$route.params.id) {
        propertyRef.value = await store.dispatch(
          'Property/find',
          context.root.$route.params.id,
        );
        payload.unit_id = context.root.$route.params.unit_id;

        payload.propertyName = propertyRef.value.short_address;
        payload.price = getPricesValueByKey(Price.PERIODIC, propertyRef.value.market_id);
        if (payload.unit_id) {
          const unitId = Number(payload.unit_id);
          const selectedUnit = propertyRef.value.units.find((unit) => unit.id === unitId);
          payload.lead = get(selectedUnit, 'last_showing_profile.lead');
          payload.tenants = get(selectedUnit, 'last_showing_profile.tenants');
          if (selectedUnit.name) {
            payload.propertyName = `${payload.propertyName} - ${selectedUnit.name}`;
          }
        }
      }
    }

    async function nextStep() {
      nextPressed = true;
      await selectStep(stepsState.active + 1);
    }

    async function selectStep(newIndex) {
      async function setStep() {
        if (newIndex <= stepsState.submit) {
          stepsState.active = newIndex;
        } else {
          await submitData();
        }
        nextPressed = false;
      }
      try {
        if (newIndex > stepsState.active) {
          context.refs.conditionReportForm.validate(async (valid) => {
            if (valid) {
              await setStep();
            }
          });
        } else {
          await setStep();
        }
      } catch (error) {
        if (!(error instanceof Error)) {
          // Validator failed
        }
      }
    }

    function updatePayload(fields) {
      let needToReevaluate = false;
      Object.keys(fields).forEach((key) => {
        if (!needToReevaluate && fields[key]) {
          needToReevaluate = true;
        }
        payload[key] = fields[key];
      });
      if (needToReevaluate && nextPressed) {
        context.refs.conditionReportForm.validate();
      }
    }

    async function submitData() {
      submittingData.value = true;
      // first check for duplicates
      try {
        await context.root.$store.dispatch('ConditionReport/checkForDuplicate', {
          unit_id: payload.unit_id,
          type: payload.type,
        });
        // no duplicates, submit report request
        try {
          payload.createdReport = await store.dispatch('ConditionReport/store', {
            instructions: payload.instructions,
            price: payload.price,
            unit_id: Number(payload.unit_id),
            type: payload.type,
            tenants: payload.tenants,
          });
          openSuccessDialog();
        } catch (error) {
          const errorMessage = get(error, 'response.data.message', error.message);
          // some error in submitting/creating the report
          showErrorNotify(context, errorMessage);
          submittingData.value = false;
        }
      } catch (error) {
        // found a duplicate
        showErrorNotify(context, error.message, { duration: 0 });
        submittingData.value = false;
      }
    }
  },
};

const defaultStepsState = {
  active: 1,
  submit: 2,
};

const defaultPayload = {
  agreementChecked: false,
  unit_id: null,
  finalConfirmChecked: false,
  tenants: [],
  instructions: null,
  price: null,
  currentRules: {},
};
</script>

<style lang="scss">
#condition-report-periodic {
  .steps-wrapper {
    width: 100%;
    left: 0;
    position: absolute;
    background: gray-color(lighter);
    display: flex;
  }

  .step-wrapper {
    flex-basis: 50%;
    height: 48px;
    cursor: pointer;
    z-index: 1;
    justify-content: center;
    display: flex;
    align-items: center;

    &.is-active,
    &.is-processed {
      .step-icon {
        background: $white;
        color: theme-color(primary);
      }

      .step-title {
        color: $white;
      }
    }

    &.is-processed {
      .step-icon {
        color: transparent;
      }

      .step-icon:before {
        content: "\e6da";
        font-family: 'element-icons' !important;
        color: theme-color(primary);
        padding: 0 5px;
      }
    }
  }

  .steps-wrapper-transition {
    transition: all 0.5s ease-in-out;
    z-index: 1;
    height: 48px;
    left: 0;
    position: absolute;
    background: theme-color(primary);
  }

  .step-icon {
    border-radius: 50%;
    background: gray-color();
    width: 24px;
    color: $white;
    height: 24px;
    text-align: center;
  }

  .step-title {
    margin-left: 16px;
    color: gray-color();
  }

  .page {
    padding-top: 5rem;
  }

  .full-width-button {
    width: 100%;
  }
}
</style>
